import {API_URL} from "../env";
import {AppStatusDto} from "../model/AppStatusDto";
import {parseAppStatus} from "../parsers/parseAppStatus";

export const getIsletNetStatus = async () => {
    try {
        const response = await fetch(API_URL + "/service/status")
        const status = await response.json() as AppStatusDto
        return parseAppStatus(status)
    } catch (e) {
        console.error(e)
        return;
    }
}

